import $ from 'jquery';
import * as http from 'superagent';
import {Requester} from "./Model/Requester";
import {FormChaines} from "./Model/FormChaines";
import {checkForm} from "./FormValidation";
import * as grecaptcha from './grecaptcha'

export namespace Faq {

  export let init = () => {
    // copy value of data-formid attribute to formId field
    function changeFormIdField(elementId) {
      let formId = $(elementId).data('formid'); // Extract info from data-formid attribute
      // Update the form's hidden input field
      $('#formId').val(formId);
    }

    function addButtonClickHandler(elementId) {
      if ($(elementId).length) {
        // element exists
        $(elementId).on("click", function (event) {
          changeFormIdField(elementId)
        });
      }
    }

    addButtonClickHandler("#suggest");
    addButtonClickHandler("#question");

    let form = document.querySelector('#modal-form form') as HTMLFormElement;

    if (form) {
      let submitButton = document.querySelector('button[type="submit"]') as HTMLButtonElement

      form.addEventListener('submit', e => {
        e.preventDefault();
        removeErrors();
        submitButton.disabled = true;

        grecaptcha.execute((token) => {
          let data: FormChaines | undefined = undefined

          let requester: Requester = new Requester(
            form.elements['requester.name'].value,
            form.elements['requester.email'].value,
          )

          data = new FormChaines(
            requester,
            form.elements['formId'].value,
            form.elements['text'].value,
            token
          );

          if (!checkForm(data)) {
            submitButton.disabled = false;

            return
          }

          if (!data) {
            return
          }

          http.post(form.action)
            .send(data)
            .set('accept', 'json')
            .then(res => {
              $('#modal-form').modal('hide')
              $('#modal-success').modal('show')
            })
            .catch(err => {
              if (err.response.statusCode == 405 || err.response.statusCode == 404) {
                $('#modal-form').modal('hide');
              }
              if (err.response.statusCode == 400 || err.response.statusCode == 500) {
                $('#modal-error').modal('show');
                document.getElementById('modal-error-message')!.innerText = "Une erreur technique est survenue, veuillez réessayer plus tard";
                submitButton.disabled = false;
              } else {
                submitButton.disabled = false;
              }
            })
        })
      })
    }

    if (window.location.hash == '#form') {
      $("#suggest").click();
      $("#question").click();
    }
  };

  let removeErrors = () => {
    let errors: any = document.getElementsByClassName('invalid-feedback');
    for (let i = 0; i < errors.length; i++) {
      errors[i].style.display = "none";
    }
  };

  $('#modal-form').on('show.bs.modal', function () {
    $('#modal-form form')[0].reset();
    (document.querySelector('button[type="submit"]') as HTMLButtonElement).disabled = false;
    removeErrors();
  });
}

Faq.init();
