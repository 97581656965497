export function checkForm(form) {
    if (form.requester.name == '') {
        document.getElementById("name")!.style.display = "block";
    }
    if (form.requester.email == '') {
        document.getElementById("email")!.style.display = "block";
    }
    if (form.text == '') {
        document.getElementById("text")!.style.display = "block";
    }

    return !(form.requester.name == '' || form.requester.email == '' || form.text == '');
}
