import * as scripts from "./script-loader";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const RECAPTCHA_SITE_KEY = '6LdMRTEmAAAAADMTEvFgkG6oRJB9Mp7nzt-OzI5Y';

export function init() {
  scripts.ensureLoaded(`https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`).then(() => {
    console.info('Google recaptcha enterprise script loaded.')
  });
}

export function execute(func: (token: string) => void) {
  window.grecaptcha.enterprise.ready(async () => {
    const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, {action: 'SEND'});
    // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
    // reCAPTCHA Enterprise to the end user's browser.
    // This token must be validated by creating an assessment.
    // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment

    func(token);

  });
}

init();
