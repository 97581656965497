import $ from 'jquery';

export namespace Accordion {
    export let init = () => {
        document.querySelectorAll("[id^='accordion']").forEach(accordion => {
            $(accordion).on('show.bs.collapse', e => show(e.target.previousElementSibling))

            $(accordion).on('shown.bs.collapse', e => {
                let header = e.target.previousElementSibling

                if (!isVisible(header)) {
                    header.scrollIntoView()
                }
            });

            $(accordion).on('hide.bs.collapse', e => hide(e.target.previousElementSibling));
        })
    };

    function show(header: HTMLElement) {
        header.classList.add('open')
    }

    function hide(header: HTMLElement) {
        header.classList.remove('open')
    }

    function isVisible(header: HTMLElement) {
        let headerRect = header.getBoundingClientRect(); // taille et position relative à la zone d'affichage
        let headerTop = headerRect.top;
        let headerBottom = headerRect.bottom;

        return (headerTop >= 0) && (headerBottom <= window.innerHeight);
    }
}

Accordion.init();
