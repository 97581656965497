import anime from "animejs/lib/anime";

export namespace Home {
    let overlay;
    let title;
    let icon;

    export let init = () => {
        anime({
            targets: '.card--home',
            opacity: [0, 1],
            translateY: [10, 0],
            easing: 'easeInOutQuad',
            duration: 800,
            delay: anime.stagger(80, {start: 100}) // increase delay by 100ms for each elements.
        });

        document.querySelectorAll('.card--home').forEach((el) => {
            el.addEventListener('mouseenter', enterAnim);
            el.addEventListener('mouseleave', leaveAnim);
        });
    };

    export let enterAnim = (e) => {
        overlay = e.target.querySelector('.card-body__overlay');
        title = overlay.querySelector('h4');
        icon = overlay.querySelector('.icon');

        anime.remove(title);
        anime.remove(icon);

        let t1 = anime.timeline({
            easing: 'easeOutExpo',
            duration: 600
        });

        t1
        .add({
            targets: overlay,
            opacity: [0, 1],
        })
        .add({
            targets: title,
            translateY: [10, 0]
        }, '-=500')
        .add({
            targets: icon,
            translateX: [-10, 0],
            opacity: [0, 1]
        }, '-=400');
    };

    export let leaveAnim = (e) => {
        anime.remove(overlay);

        anime({
            targets: overlay,
            opacity: [1, 0],
            easing: 'easeOutExpo',
            duration: 400
        })
    };
}

Home.init();
