
import $ from 'jquery';

let loadingQueues = {};

export function ensureLoaded(src) {
    return new Promise<void>(function (resolve) {
        let scripts = getScriptsBySrc(src);

        if (scripts.length === 0) {
            insertScript(src).then(() => {
                if (loadingQueues[src]) {
                    loadingQueues[src].forEach(item => item())

                    delete loadingQueues[src];
                }

                resolve()
            })
        } else if (scripts.find(isDataLoaded)) {
            resolve()
        } else {
            if (!loadingQueues[src]) {
                loadingQueues[src] = [];
            }

            loadingQueues[src].push(resolve);
        }
    })
}

export function getScriptsBySrc(src: string): HTMLScriptElement[] {
    return $('script').filter(script => script.src == src)
}

export function isLoaded(src: string) {
    let scripts = getScriptsBySrc(src)

    return scripts.length > 0 && scripts.find(isDataLoaded);
}

export function getScriptsToLoad() {
    return $('[data-role="load-script"]').map(item => item.getAttribute('data-src'))
}

function isDataLoaded(script: HTMLScriptElement) {
    return script.getAttribute('data-loaded') != null
}

function insertScript(url: string) {
    let script = document.createElement('script') as HTMLScriptElement

    script.async = true
    script.src = url

    let promise = new Promise<void>(function (resolve) {
        script.onload = function () {
            script.setAttribute('data-loaded', 'true');

            resolve()
        };
    });

    let firstScript = document.getElementsByTagName('script')[0]

    firstScript!.parentNode!.insertBefore(script, firstScript)

    return promise
};
