import Lazyload from 'vanilla-lazyload';
import anime from 'animejs';

export namespace LazyloadImage {
    let lazy: any;

    let onLoad = function(el) {
        anime.remove(el);
        anime({
            targets: el,
            opacity: [0, 1],
            duration: 300,
            delay: 30,
            easing: "easeInOutExpo",
            complete: createHoverAnim(el)
        });
    };

    export let init = function() {
        const LAZYLOAD_OPTIONS = {
            elements_selector: '.lazy',
            class_loading: 'lazyloading',
            class_loaded: 'lazyloaded',
            callback_loaded: onLoad
        };

        lazy = new Lazyload(LAZYLOAD_OPTIONS);
    };

    let createHoverAnim = (el) => {
        let hoverEl = el.closest('[data-anim]');

        if (!hoverEl) return;

        hoverEl.addEventListener('mouseenter', () => {
            anime.remove(el);
            anime({
                targets: el,
                opacity: 1,
                scale: 1.05,
                easing: "easeOutExpo",
                duration: 400
            })
        });
        hoverEl.addEventListener('mouseleave', () => {
            anime.remove(el);
            anime({
                targets: el,
                opacity: 1,
                scale: 1,
                easing: "easeOutExpo",
                duration: 500,
            })
        });
    };
}

LazyloadImage.init();
