import anime from "animejs/lib/anime";

export namespace Footer {
    let clone: Element;

    export let init = () => {
        document.querySelectorAll(".site-footer .nav-item").forEach(bindEvents);
    };

    let bindEvents = el => {
        el.addEventListener('mouseenter', enterAnim);
        el.addEventListener('mouseleave', leaveAnim);
    };

    let enterAnim = (e) => {
        createColorClone(e.target);

        anime({
            targets: clone,
            opacity: [0, 1],
            duration: 200,
            easing: 'easeInOutQuad'
        });
    };

    let createColorClone = (el) => {
        let imgEl = el.querySelector('img');

        if (imgEl) {
            let colorLogoSrc = imgEl.getAttribute('src').replace('-white', '');
            clone = imgEl.cloneNode();
            clone.setAttribute('src', colorLogoSrc);
            el.querySelector('a').appendChild(clone);
        }
    };

    let leaveAnim = () => {
        if (clone) {
            clone.remove()
        }
    };
}

Footer.init();
