import {Requester} from "./Requester";

export class FormChaines {
  requester: Requester;
  formId: string;
  text: string;
  captcha: string;

  constructor(requester: Requester, formId: string, text: string, captcha: string) {
    this.requester = requester;
    this.formId = formId;
    this.text = text;
    this.captcha = captcha;
  }
}
